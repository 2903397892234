body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

ol {
  list-style-type: decimal;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 15px;
  unicode-bidi: isolate;
}
ul {
  display: block;
  list-style-type: circle;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 15px;
  unicode-bidi: isolate;
}

.chat-start {
  margin-bottom: 5px;
}
.chat.chat-start .chat-image.avatar{
  max-height: 32px;
  max-width: 32px;
}
.chat-bubble {
  font-size: 0.9rem;
  padding: 0.7rem 1rem;
}
.chat-input {
  font-size: 0.9rem;
  height: 2.5rem
}
.chat-header {
  padding: 5px 15px;
}
.send-button {
  height: 2.5rem!important;
  min-height: unset;
  max-height: unset;
}